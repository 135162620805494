<template>
    <div id="mappedin-map" class="mappedin"></div>
</template>

<script setup>
import { watch, onMounted } from 'vue'
import { mappedinEvent } from '../../../sockets-events/MappedinEvent'
import { getMapData, show3dMap } from '@mappedin/mappedin-js'
import '@mappedin/mappedin-js/lib/index.css'

var mapData = ""
var mapView = ""
var marker = ""
var floors = [];
const prosafeIcon = require('@/assets/images/prosafe-icon-23.png')
const associateImage = require('@/assets/images/stephanhruszkewycz-hr.jpg')
const props = defineProps(['location'])
const { joinMappedinEvent, leaveMappedinEvent } = mappedinEvent()

// * Set Mappedin credentials
const options = {
    key: 'mik_hmpOYDiJGQEaBqwqV566a07b4',
    secret: 'mis_FJRlOC3VqBuvPcjY8XtfS2w98TR8p4GnnGJEAhu8R8z847b0fd2',
    mapId: '67d33fe53b90e4000beb5580',
}

const makeCords = (lat, lng, floor) => {
    return mapView.createCoordinate(lat, lng, floor)
}

onMounted(async () => {
    // * Init Mappedin
    await initMappedin()

    // * Set label in the spaces
    // setLabels()
    mapView.auto();

    // * Join in Mappedin socket
    joinMappedinSocket()
})

function joinMappedinSocket() {
    joinMappedinEvent()
        .listen(".mappedin-location", async (data) => {
            console.log(data)

            const newCoordinates = makeCords(data.cords.lat, data.cords.lng, null)

            if (!marker) return setMarker(newCoordinates, data.associate)
            else return updateMarkerPosition(newCoordinates)
        })
}

async function initMappedin() {
    mapData = await getMapData(options);
    mapView = await show3dMap(document.querySelector('#mappedin-map'), mapData)

    // Set map camera position
    mapView.Camera.set({
        bearing: 170,
        zoomLevel: 21,
        pitch: 60,
    })

    // Set camera distance in floors height
    mapView.StackedMaps.expand({ distanceBetweenFloors: 7 })

    // Set floors ids
    mapData.getByType("floor").forEach((floor) => { floors.push(floor) })
}

function setLabels() {
    mapData.getByType('space').forEach((space) => {
        // mapView.updateState(space, {
        //     color: '#000000',
        //     interactive: true,
        //     hoverColor: '#000000',
        // })

        if (space.name) {
            mapView.Labels.add(space, space.name, {
                appearance: {
                    marker: {
                        foregroundColor: {
                            active: '#20BEEE'
                        },
                        icon: prosafeIcon
                    },
                    text: {
                        foregroundColor: '#005985'
                    }
                }
            })
        }
    })
}

function setMarker(newCoordinates, associateInfo) {
    const markerTemplate = `
        <div class='associate-marker'>
            <img src='${associateImage}' class='associate-marker__image'> 
            <span class='associate-marker__name'>${associateInfo.name}</span>
        </div>
    `

    if (!marker) {
        marker = mapView.Markers.add(newCoordinates, markerTemplate, { rank: 'always-visible' })
    }
}

function updateMarkerPosition(newCoordinates) {
    mapView.Markers.animateTo(marker, newCoordinates, {
        duration: 1000,
        easing: "linear",
    })
}
</script>