import { useClientStore } from '@/stores/ClientStore.js'

export function mappedinEvent() {
    const { client } = useClientStore()

    // * Subscribe to Mappedin event
    function joinMappedinEvent() {
        console.log('Join in Mappedin channel ✅')

        return Echo.private(`mappedin.${client.hotelId}`)
    }

    // * Leave to Mappedin event
    function leaveMappedinEvent() {
        Echo.leave(`mappedin.${client.hotelId}`)
    }

    return { joinMappedinEvent, leaveMappedinEvent }
}